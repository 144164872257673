import React, { useState, memo } from 'react'
import { Button, Message, Icon, Popup, Modal, Header, Input, Select } from 'semantic-ui-react'
import { patchBriefing } from '../core/briefingCache'
import { usePost } from '../hooks/restHooks'
import { isBaas15Active } from '../helpers/baasHelper'

const { countryOptions, languageOptionsRaw } = require('@douglas/baas-shared')

const PublishToHybris = ({ briefing, callback }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState({ visible: false })
  const [hybrisJson, setHybrisJson] = useState({ visible: false })
  const [open, setOpen] = useState(false)
  const [updatedIdentifier, setUpdatedIdentifier] = useState('')
  const [updatedLanguage, setUpdatedLanguage] = useState('')
  const isPublished = Boolean(briefing.publishedToHybris)
  const publishEndpoint = isBaas15Active ? 'cms/publish-json/' : 'cms/publish/'
  const { trigger } = usePost(publishEndpoint + briefing._id)

  const onPublishClicked = () => {
    setIsLoading(true)
    setHybrisJson({ visible: false })
    trigger(briefing).then(triggerResponse => {
      if (triggerResponse.statusCode === 200) {
        let publishedFirstDate = briefing.publishedFirstDate
        if (!briefing.publishedFirstDate) {
          publishedFirstDate = Date.now()
        }
        let patchObject = { publishedToHybris: true, publishedLastDate: Date.now(), publishedFirstDate: publishedFirstDate }
        patchBriefing(briefing._id, patchObject).then(patchResponse => {
          if (patchResponse.status === 200) {
            let hybrisName = ''
            let hybrisUid = ''

            if (triggerResponse.body && triggerResponse.body.page) {
              hybrisName = triggerResponse.body.page.name
              hybrisUid = triggerResponse.body.page.uid
            }

            const additionalText = '<br /><br /><b>Name:</b> ' + hybrisName + '<br /><b>Uid:</b> ' + hybrisUid
            setMessage({
              visible: true,
              color: 'green',
              error: false,
              text: '<div>Briefing published successfully' + additionalText + '</div>',
            })
          } else {
            setMessage({
              visible: true,
              color: 'red',
              error: true,
              text: 'Something went wrong while updating the record in the database',
            })
          }
        })
      } else {
        const helpLink =
          '<br /><h3>Need Help?</h3>' +
          '<b><a href="https://douglas-group.atlassian.net/wiki/spaces/T2/pages/490078288/Howto+troubleshoot+Baas+errors" target="_blank">Howto troubleshoot Baas errors</a></b>'

        setMessage({
          visible: true,
          color: 'red',
          error: true,
          text: `<h3>Something went wrong:</h3>${triggerResponse.body.errors[0].message} ${helpLink}`,
        })

        const beautyfiedJson = JSON.stringify(triggerResponse.body.requestJson, null, 2)
        setHybrisJson({
          visible: true,
          color: 'red',
          error: true,
          text: beautyfiedJson,
        })
      }
      callback && callback()
    })
  }

  const onUpdateBriefing = async () => {
    const json = {}
    if (updatedIdentifier) {
      json.identifier = updatedIdentifier
    }
    if (updatedLanguage) {
      json.language = updatedLanguage
    }
    if (Object.keys(json).length) {
      await patchBriefing(briefing._id, json)
      window.location.reload(false)
    }
    setOpen(false)
  }

  const onChangeIdentifier = e => {
    setUpdatedIdentifier(e.target.value)
  }

  const onChangeLanguage = (_, f) => {
    setUpdatedLanguage(f.value)
  }

  React.useEffect(() => {
    if (message.visible) {
      setIsLoading(false)
    }
  }, [message])

  const canPublish = briefing.identifier && briefing.language

  const modal = (
    <>
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={
          <Button icon labelPosition="left" primary>
            <Icon name="edit outline" />
            Add required fields
          </Button>
        }
      >
        <Modal.Header>Add required field to publish briefing to hybris</Modal.Header>
        <Modal.Content image>
          {!briefing.identifier && (
            <Modal.Description>
              <Header>Page ID</Header>
              <Input placeholder="Page ID" value={updatedIdentifier} onChange={onChangeIdentifier} />
            </Modal.Description>
          )}
          {!briefing.language && (
            <Modal.Description>
              <Header>Language</Header>
              <Select
                errormessage="Please pick a language"
                label="Language"
                name="language"
                options={languageOptionsRaw.filter(lang =>
                  countryOptions.find(c => c.value === briefing.country).lang.includes(lang.key)
                )}
                selectedvalue={updatedLanguage}
                onChange={onChangeLanguage}
              />
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button content="Update" labelPosition="right" icon="checkmark" onClick={onUpdateBriefing} positive />
        </Modal.Actions>
      </Modal>
      <Button.Or text=">" />
    </>
  )

  const button = (
    <Button
      primary={!isPublished}
      secondary={isPublished}
      onClick={onPublishClicked}
      disabled={isLoading || !canPublish}
      icon
      labelPosition="left"
    >
      {isLoading ? <div className="ui active inverted small loader tiny" /> : <Icon name="arrow up" />}
      {briefing.publishedToHybris ? 'Republish to Hybris' : 'Publish to Hybris'}
    </Button>
  )
  return (
    <>
      <Button.Group>
        {!canPublish ? (
          <>
            {modal}
            <Popup
              content={'Required fields are missing for this function. Please add them via the left button'}
              trigger={<span>{button}</span>}
            />
          </>
        ) : (
          button
        )}
      </Button.Group>
      {message.visible && (
        <Message style={{ width: '100%' }} color={message.color}>
          <div dangerouslySetInnerHTML={{ __html: message.text }} />
        </Message>
      )}

      {/* hybrisJson.visible &&  (
        <Message style={{ width: '100%' }} color={hybrisJson.color}>
          <h5>This can be sent to a developer:</h5>
          <textarea style={{ width: '100%', height: '400px', fontFamily: 'monospace', fontSize: '12px' }}>
            {hybrisJson.text}
          </textarea>
        </Message>
      )
        */}
    </>
  )
}

export default memo(PublishToHybris)
